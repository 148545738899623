import React from 'react';

import Layout from '../../components/blocks/Layout';
import SEO from '../../components/blocks/SEO';
import Testimonials from '../../components/slides/stealth-selling/upsell/Testimonials';
import PartnerHeader from '../../components/slides/offers/Offer/PartnerHeader';
import PartnerFeatures from '../../components/slides/offers/Offer/PartnerFeatures';

const partner = {
  trialUrl: 'https://app.deadlinefunnel.com/upgrade/pro',
  ctaUrl: 'https://jackborn.samcart.com/products/stealth-selling-m',
  wistiaHash: 'j1v4z8r1bi',
  thankYouPage: false
}

const UpsellStealthSelling = () => (
  <Layout >
    <SEO title="Stealth Selling | Deadline Funnel" />
    <PartnerHeader partner={partner} />
    <PartnerFeatures ctaUrl={partner.ctaUrl} />
    <Testimonials />
  </Layout>
);

export default UpsellStealthSelling;
