import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Modal } from 'reactstrap';

import styles from "./Testimonials.module.css";

import Container from "../../../blocks/Container";
import Image from "../../../elements/Image";

import CloseModalImg from "../../../../images/svg/modal_close.svg";
import Testimonial from "./Testimonial";
import FullWidthRow from "../../../blocks/FullWidthRow";

const Testimonials = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: {fileAbsolutePath: {regex: "/stealth-testimonials.md/"}}
      ) {
        edges {
          node {
            frontmatter {
              testimonials {
                name
                position
                web
                quote
                review
                link
                image
                content
                hasMoreContent
              }
            }
          }
        }
      }
    }
  `);

  const {
    testimonials,
  } = data.allMarkdownRemark.edges[0].node.frontmatter;

  const [testimonialModal, setTestimonialModal] = React.useState({
    open: false,
    data: {}
  });

  const onClose = () => {
    setTestimonialModal({
      open: false,
      data: {}
    });
  };

  const toggle = () => {
    setTestimonialModal({
      ...testimonialModal,
      open: !testimonialModal.open,
    })
  };

  const handleOpenModal = (name, position, web, quote, review, content, image) => {
    setTestimonialModal({
      open: true,
      data: {
        name,
        position,
        web,
        quote,
        review,
        content,
        image,
      }
    });
  }

  const handleLoadMore = () => {
    //
  }

  return (
    <div className={styles.testimonials} id="testimonials-container">
      <Container type="big">
        <Container type="mobile">
          <div className={styles.testimonialsContainer}>
            {testimonials.map((testimonial, i) => {
              const {
                name,
                position,
                web,
                quote,
                review,
                content,
                image,
                hasMoreContent
              } = testimonial;
              return (
                <div className={styles.testimonialContainer} key={i}>
                  <div className={styles.testimonialHeader}>
                    <div className={styles.testimonialImage}>
                      <Image name={image} />
                    </div>
                    <div className={styles.testimonialMetaContainer}>
                      <div className={styles.testimonialName}>{name}</div>
                      <div className={styles.testimonialPosition}>
                        {position}
                      </div>
                      <div className={styles.testimonialWeb}>{web}</div>
                    </div>
                  </div>
                  <div className={styles.testimonialBody}>
                    <div className={styles.testimonialQuote}>{quote}</div>
                    <div
                      className={styles.testimonialReview}
                      dangerouslySetInnerHTML={{ __html: review }}
                    />
                  </div>
                  <div className={styles.testimonialFooter}>
                    {hasMoreContent && (
                      <span onClick={() => handleOpenModal(name, position, web, quote, review, content, image)}>
                        Continue reading
                      </span>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <FullWidthRow className="pb-5">
            <div className="d-flex justify-content-center">
              <button onClick={handleLoadMore} className={styles.testimonials__loadMoreButton}>
                Show More Reviews
              </button>
            </div>
          </FullWidthRow>
        </Container>
        <Modal
          isOpen={testimonialModal.open}
          className={`${styles.modal}`}
          contentClassName={styles.modalContent}
          backdropClassName={styles.modalBackdrop}
          centered={true}
          toggle={toggle}
          container={() => document.querySelector("#testimonials-container")}
        >
          <div
            onClick={() => onClose()}
            onKeyDown={() => onClose()}
            role="button"
            tabIndex={0}
          >
            <CloseModalImg className={styles.modalClose} />
          </div>
          <div className={styles.modalContent__text}>
            <Testimonial
              name={testimonialModal.data.name}
              position={testimonialModal.data.position}
              web={testimonialModal.data.web}
              quote={testimonialModal.data.quote}
              review={testimonialModal.data.review}
              content={testimonialModal.data.content}
              image={testimonialModal.data.image}
            />
          </div>
        </Modal>
      </Container>
    </div>
  );
};

export default Testimonials;
