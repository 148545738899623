import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Modal } from 'reactstrap';

import styles from "./Testimonials.module.css";

import Image from "../../../elements/Image";


const Testimonial = ({
  name,
  position,
  web,
  quote,
  review,
  content,
  image,
}) => {
  return (
    <div className={styles.testimonialModalContainer}>
      <div className={styles.testimonialHeader}>
        <div className={styles.testimonialImage}>
          <Image name={image || ''} />
        </div>
        <div className={styles.testimonialMetaContainer}>
          <div className={styles.testimonialName}>{name}</div>
          <div className={styles.testimonialPosition}>
            {position}
          </div>
          <div className={styles.testimonialWeb}>{web}</div>
        </div>
      </div>
      <div className={styles.testimonialBody}>
        <div className={styles.testimonialQuote}>{quote}</div>
        <div
          className={styles.testimonialReview}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </div>
  );
};

export default Testimonial;
